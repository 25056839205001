import { ArrowBack } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Typography,
} from '@mui/material'
import { useState } from 'react'

import { Icon } from '@/shared/components/icon'
import { NAV_CLASSES } from '@/shared/consts/onboarding'
import { useMedia } from '@/shared/utils/media'

import { content, sections } from './consts'
import { SectionKey } from './enum'
import * as Styled from './styled'

export const HelpMain = () => {
  const { isDesktop } = useMedia()
  const isMobileView = !isDesktop
  const defaultSelectedSection = isMobileView ? null : SectionKey.HowTo
  const [selectedSection, setSelectedSection] = useState<SectionKey | null>(defaultSelectedSection)
  const [openModal, setOpenModal] = useState(false)

  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)
  const handleSectionChange = (sectionId: SectionKey) => {
    setSelectedSection(sectionId)
  }

  return (
    <Styled.Root>
      <Container className={NAV_CLASSES.HELP.PAGE} maxWidth='lg' sx={{ textAlign: 'center' }}>
        {/* Заголовок страницы */}
        <Typography color='text.primary' fontSize={32} mb={4}>
          Раздел помощи
        </Typography>

        {/* Контейнер для левого столбца и контента */}
        <Box sx={{ display: 'flex', flexDirection: isMobileView ? 'column' : 'row' }}>
          {/* Левая часть - Навигация */}
          {(isMobileView && selectedSection === null) || !isMobileView ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'stretch',
                minWidth: isDesktop ? '240px' : '100%',
                mt: isDesktop ? '52px' : 0,
                mr: 4,
              }}
            >
              {sections.map(({ id, label, icon }) => (
                <Button
                  key={id}
                  variant='contained'
                  startIcon={<Icon name={icon} />}
                  onClick={() => handleSectionChange(id as SectionKey)}
                  sx={{ width: '100%' }}
                >
                  {label}
                </Button>
              ))}
              <Button
                variant='outlined'
                startIcon={<Icon name='ContactMail' />}
                onClick={handleOpen}
                sx={{ width: '100%' }}
              >
                Напишите нам
              </Button>
            </Box>
          ) : null}

          {/* Правая часть - Контент */}
          {(isMobileView && selectedSection !== null) || !isMobileView ? (
            <Box sx={{ flexGrow: 1 }}>
              {selectedSection && (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    {isMobileView && (
                      <IconButton onClick={() => setSelectedSection(null)} sx={{ alignSelf: 'flex-start', mr: 1 }}>
                        <ArrowBack />
                      </IconButton>
                    )}
                    <Typography color='text.primary' fontSize={28}>
                      {sections.find((section) => section.id === selectedSection)?.label}
                    </Typography>
                  </Box>
                  {content[selectedSection].map(({ question, answer }, index) => (
                    <Accordion key={index}>
                      <AccordionSummary sx={{ textAlign: 'left' }}>
                        <Typography fontSize={18} sx={{ color: 'text.primary' }}>
                          {question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ textAlign: 'left' }}>
                        <Typography fontSize={16} sx={{ color: 'text.primary' }}>
                          {answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </>
              )}
            </Box>
          ) : null}
        </Box>

        {/* Модальное окно */}
        <Modal open={openModal} onClose={handleClose}>
          <Styled.ModalBox>
            <Typography variant='h6' color='text.primary'>
              Как с нами связаться
            </Typography>
            <Typography color='text.secondary' mt={2}>
              Вы можете связаться с нами через электронную почту support@example.com.
            </Typography>
            <Button onClick={handleClose} sx={{ mt: 3 }} variant='contained'>
              Закрыть
            </Button>
          </Styled.ModalBox>
        </Modal>
      </Container>
    </Styled.Root>
  )
}
